import React from "react";

const Home = React.lazy(() => import("./pages/home"));
const Login = React.lazy(() => import("pages/login"));
// const Register = React.lazy(() => import("pages/register"));
const QRScanner = React.lazy(() => import("pages/qr_scanner"));
const NotFound = React.lazy(() => import("pages/notfound"));

const AdminQRCode = React.lazy(() => import("pages/admin/qrcodes"));
const AdminUsers = React.lazy(() => import("pages/admin/users"));
const AdminReport = React.lazy(() => import("pages/admin/reports"));
const AdminSettings = React.lazy(() => import("pages/admin/settings"));
const AdminSecurity = React.lazy(() => import("pages/admin/security"));

const CustomerProfile = React.lazy(() => import("pages/customer/profile"));
const CustomerQRCode = React.lazy(() => import("pages/customer/qrcodes"));
const CustomerReport = React.lazy(() => import("pages/customer/reports"));
const CustomerSecurity = React.lazy(() => import("pages/customer/security"));

const routes = [
  { path: "/", exact: true, name: "Home", element: Home },
  { path: "/login", name: "Login", element: Login },
  // { path: "/register", name: "Register", element: Register },
  { path: "/qr_scanner", name: "QRScanner", element: QRScanner },
  { path: "/*", name: "NotFound", element: NotFound },
];

const authRoutes = [
]

const customerRoutes = [
  { path: "/profile", name: "Profile", element: CustomerProfile },
  { path: "/qrcodes", name: "QRCode", element: CustomerQRCode },
  { path: "/report", name: "Report", element: CustomerReport },
  { path: "/security", name: "Security", element: CustomerSecurity },
];

const adminRoutes = [
  { path: "/qrcodes", name: "QRCode", element: AdminQRCode },
  { path: "/report", name: "Report", element: AdminReport },
  { path: "/users", name: "User", element: AdminUsers },
  { path: "/settings", name: "Setting", element: AdminSettings },
  { path: "/security", name: "Security", element: AdminSecurity },
]

export default routes;
export { authRoutes, adminRoutes, customerRoutes };